import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../../components/layout'
import styles from './index.module.css'
import { Grid, Typography, } from '@material-ui/core'

class Comunicato extends React.Component {
  render() {
    const comunicato = get(this.props, 'data.contentfulComunicato')

    const siteTitle = `Movimento Rivoluzione Fiscale - ${get(
      this,
      'props.data.site.siteMetadata.title'
    )}`

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${comunicato.titolo} | ${siteTitle}`}>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <div className={styles.content}>
                  <Typography variant={'h3'}>{comunicato.titolo}</Typography>
                  {comunicato.autore && (
                    <Typography variant={'subtitle1'} className={styles.author}>
                      {`${comunicato.autore.nome} ${comunicato.autore.cognome}, ${comunicato.dataPubblicazione}`}
                    </Typography>
                  )}
                  <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                      __html: comunicato.contenuto.childMarkdownRemark.html,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Comunicato

export const pageQuery = graphql`
  query ComunicatoBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulComunicato(slug: { eq: $slug }) {
      id
      titolo
      contenuto {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
